$(document).ready(function() {


   //nav-header
    $(".btn-navbar").on('click', function(e) {
      $(".nav-list__inner").toggleClass("active");
    });

 
	//top-slider
	  $('.content-slider').slick({
	      infinite: true,
	      slidesToScroll: 1,
	      slidesToShow: 1,
	      dots: false,
	      arrows: true,
	      autoplay: true,
	      speed: 3000,
	      fade: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              arrows: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              arrows: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false
            }
          }
        ]
	  });

    //product-bar__slider
    $('.product-bar__slider').slick({
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 4,
        dots: false,
        arrows: true,
        autoplay: true,
        speed: 2000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              arrows: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              arrows: false
            }
          }
        ]
    });


    //maskedinput
    $(".request__input-phone").mask("+7 (99)-999-99-99"); 



    //top-nav
    $(".nav-categories__mobile-show").on('click', function(e) {
      $(".nav-categories__inner").toggleClass("show");
    });

    $('#categories li:has(ul)').doubleTapToGo();




    //select
    $('.order_list__select').customSelectMenu();



	 //product-detail-slider
   $('.slider-for').slick({
     slidesToShow: 1,
     slidesToScroll: 1,
     arrows: false,
     fade: true,
     asNavFor: '.slider-nav'
   });

   $('.slider-nav').slick({
     slidesToShow: 4,
     slidesToScroll: 1,
     asNavFor: '.slider-for',
     dots: false,
     arrows: false,
     focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true
          }
        }
      ]
   });


   //quantity-card  
    $('.quantity__minus-btn, .basket-quantity__minus').on('click', function(e) {
      e.preventDefault();
      var $this = $(this);
      var $input = $this.closest('div').find('input');
      var value = parseInt($input.val());

      if (value > 1) {
        value = value - 1;
      } else {
        value = 0;
      }
      $input.val(value);
    });

    $('.quantity__plus-btn, .basket-quantity__plus').on('click', function(e) {
      e.preventDefault();
      var $this = $(this);
      var $input = $this.closest('div').find('input');
      var value = parseInt($input.val());

      if (value < 100) {
        value = value + 1;
      } else {
        value =100;
      }
      $input.val(value);
    });
  //quantity--
 

	
});